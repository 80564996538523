import React from "react"
import BackgroundImage from "gatsby-background-image"
import ClimateCheckLogo from "../../../ui/ClimateCheckLogo/ClimateCheckLogo"
import { Link } from "gatsby"

const Thanks = ({ homeHero1 }) => {
  return (
    <BackgroundImage
      fluid={homeHero1.childImageSharp.gatsbyImageData}
      style={{ width: "100vw", height: "100vh" }}
    >
      <div className="thanks">
        <header>
          <ClimateCheckLogo />
        </header>
        <h1>Thank you!</h1>
        <p>We'll call or email you soon.</p>
        <p><a href="mailto:info@climatecheck.com">info@climatecheck.com</a></p>
        <Link to="/solutions" className="learn-more-link link-btn">
          <strong>← Back to our site</strong>
        </Link>
      </div>
    </BackgroundImage>
  )
}

export default Thanks
